

// generated code

export interface AppInfo {
   packageVersion: string,
   gitShortHash: string,
   gitHash: string,
   commitDateShort: string,
   commitDateTimeShort: string
   fullVersion: string
}

const appInfo : AppInfo = {
  packageVersion : '0.1.0',
  gitShortHash: 'f75d00a',
  gitHash: 'f75d00a308beb99d8f94454d3faf01c538bac3c1',
  commitDateShort: '2025-02-13',
  commitDateTimeShort: '2025-02-13T16:21:47',
  fullVersion: '0.1.0-2025-02-13.f75d00a'
};

export default appInfo;

